<template>
  <div class="about-us-page">
    <div class="banner" :style="{'background-image': 'url(' + configInfo.about_us_top_image + ')'}" />
<!--    about_us_top_image-->
    <div class="about-us-blank">
      <div class="content-wrap">
        <div class="left">
          <img :src="configInfo.about_us_left_image" />
        </div>
        <div class="right">
          <div class="title">
            <span class="chinese">{{ configInfo.about_us_name }}</span>
            <span class="english">About us</span>
          </div>
          <div class="info">
            <p>{{ configInfo.about_us_text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Utils from '@/utils';
export default {
  name: 'aboutUs',
  data() {
    return {
      configInfo: {}
    };
  },
  mounted() {
    this.configInfo = Utils.getLocalStorage('config_info');
  }
};
</script>

<style lang="scss" scoped>
.about-us-page {
  width: 100%;
  .banner {
    width: 100%;
    height: 380px;
    background-image: url("../assets/img/about_us_banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .about-us-blank {
    position: relative;
    margin: 85px 0 94px;
    .content-wrap {
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 10;
      .left {
        width: 460px;
        height: 660px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 60px;
        .title {
          position: relative;
          margin-top: 10px;
          padding-bottom: 28px;
          line-height: 1;
          .chinese {
            font-size: 28px;
            font-weight: bold;
            color: #1E66FF;
            margin-right: 29px;
          }
          .english {
            font-size: 18px;
            font-weight: 400;
            color: #1E66FF;
          }
        }
        .title:after {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          width: 40px;
          height: 4px;
          background: #1E66FF;
        }
        .info {
          margin-top: 24px;
          p {
            font-size: 14px;
            color: #666666;
            line-height: 32px;
          }
        }
      }
    }
  }
  .about-us-blank:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    min-width: 390px;
    width: calc(((100vw - 1200px) / 2) + 390px );
    height: 204px;
    background: #2869F5;
  }
}
</style>
